<template>
    <div :style="getStyle(data)" :class="getClass(data)">
        <div :class="getClass(data, true)" :style="getStyle(data, true)">
            <div class="content" :class="getClass(content)" :style="getStyle(content)" v-for="(content, n) in data.Content" :key="n">
                <div class="content-container" :class="getClass(content, true)" :style="getStyle(content, true)">
                    <!-- left -->
                    <div :class="getClass(content.Left)" :style="getStyle(content.Left)" v-if="content.Left">
                        <div :class="getClass(left, true)" :style="getStyle(left, true)" v-for="(left, indexContentLeft) in content.Left.Data" :key="indexContentLeft">
                            <template v-if="conditionHideWhen(left.HideWhen, indexContentLeft)">
                                <text-component v-if="left.Type === 'text'" :data="left" />
                                <image-component v-if="left.Type === 'image'" :data="left" />
                                <buttons-component v-if="left.Type === 'buttons'" :data="left" />
                                <form-component v-if="left.Type === 'form'" :data="left" />
                                <list-component v-if="left.Type === 'list'" :data="left" />
                                <tabs-component v-if="left.Type === 'tabs'" :data="left" />
                                <icons-component v-if="left.Type === 'icons'" :data="left" />
                                <tag-component v-if="left.Type === 'tag'" :data="left" />
                            </template>
                        </div>
                    </div>
                    <!-- right -->
                    <div :class="getClass(content.Right)" :style="getStyle(content.Right)" v-if="content.Right">
                        <div :class="getClass(right, true)" :style="getStyle(right, true)" v-for="(right, indexContentRight) in content.Right.Data" :key="indexContentRight">
                            <template v-if="conditionHideWhen(right.HideWhen, indexContentRight)">
                                <text-component v-if="right.Type === 'text'" :data="right" />
                                <image-component v-if="right.Type === 'image'" :data="right" />
                                <form-component v-if="right.Type === 'form'" :data="right" />
                                <buttons-component v-if="right.Type === 'buttons'" :data="right" />
                                <collapse-component v-if="right.Type === 'collapse'" :data="right" />
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {
        TextComponent: () => import('./components/text'),
        ImageComponent: () => import('./components/image'),
        ButtonsComponent: () => import('./components/buttons'),
        FormComponent: () => import('./components/form'),
        ListComponent: () => import('./components/list'),
        CollapseComponent: () => import('./components/collapse'),
        TabsComponent: () => import('./components/tabs'),
        IconsComponent: () => import('./components/icons'),
        TagComponent: () => import('./components/tag')
    },
    props: {
        data: {
            type: Object
        }
    }
}
</script>
